.EventPost_heroImage {
  height: 560px;
  width: 100%;
  margin-bottom: -165px;
  background-size: cover;
  background-position: 50%;
  background-attachment: fixed;
}

@media (max-width: 1024px) {
  .EventPost_heroImage {
    height: 340px;
    margin-bottom: -40px;
  }
}
.EventPost_heroCover {
  background-color: white;
  padding-top: 60px;

  -ms-grid-column: 3;

  -ms-grid-column-span: 8;

  grid-column: 3 / 11;
}

.EventPost_content {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
}

@media all and (max-width: 1024px) {
  .EventPost_heroCover {
    padding-top: 0;
    -ms-grid-column: 2;
    -ms-grid-column-span: 10;
    grid-column: 2/ 12;
    -ms-grid-column-align: center;
    justify-self: center;
  }
  .EventPost_title {
    text-align: center;
  }
  .EventPost_meta {
    text-align: center;
  }
}

.EventPost_title {
  margin: 0;
  margin-bottom: 40px;
  padding: 20px 0 40px 0px;

  border-bottom: 3px solid;
  -o-border-image: -o-linear-gradient(
      left,
      var(--consumer-pink),
      var(--consumer-orange)
    )
    1;
  border-image: linear-gradient(
      to right,
      var(--consumer-pink),
      var(--consumer-orange)
    )
    1;
  max-width: 590px;
}

.EventPost_snippet {
  font-weight: 300;
  line-height: 25px;
}

.EventPost_blurb:first-letter {
  font-size: 80px;
  font-weight: 700;
  line-height: 0.9;
  float: left;
  margin-right: 10px;
}
